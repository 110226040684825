.FiltersDrawer {
    min-height: 100vh;

    .FiltersDrawerContent {
	min-height: 100vh;

	.Wrapper {
	    padding: 50px;
	    box-sizing: border-box;

	    .Filters {
		margin-top: 30px;

		h3 {
		    text-decoration: underline;
		}

		div {
		    margin-bottom: 15px;
		}
	    }

	    .Actions {
		margin-top: 50px;

		button:first-of-type {
		    margin-right: 20px;
		}
	    }

	}
    }
}
