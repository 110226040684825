.Categories, .RequestType, .ProductGroup {

    margin-bottom: 30px;

    h5 {
	margin-bottom: 10px;
    }

    p {
	margin-block-start: 0;
	margin-block-end: 0;
    }
}
