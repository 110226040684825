@import "../../mixins.scss";

.Form {
    height: 85vh;

    .Wrapper {
	background-color: #fff;
	padding: 50px 100px 50px 100px;

	& > div {
	    margin-bottom: 30px;
	}

	@include gt-xs {
	    margin-top: 300px;
	}

    }

}
