.Feedback {
    width: 600px;
    height: 400px;

    p {

	color: #6a6a6a;
	font-weight: 600;
	max-width: 80%;
    }
}
