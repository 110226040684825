@import "../../variables.scss";
@import "../../mixins.scss";


.CreateQuote {
    height: 100%;
    padding: 50px 100px;
    box-sizing: border-box;

    .Wrapper {
	width: 100%;
    }
}

// SHARED WITH CHILDREN

.FormStep {

    h3 {
	margin-bottom: $form-margin;
	border-bottom: 1px solid #000;
	padding-bottom: 5px;
    }
}

.Form {

    .FormLine {
	margin-bottom: $form-line-margin;

	div {
	    margin-right: $form-input-margin;
	}
    }
}
