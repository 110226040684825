@import "../../mixins.scss";

.Layout {
    padding-top: 100px;

    @include gt-xs {
	padding-top: 125px;
	min-height: 100vh;
    }

}
