@mixin xs {
    @media (max-width: 599px) {
        @content;
    }
}

@mixin gt-xs {
    @media (min-width: 600px) {
        @content;
    }
}

@mixin gt-sm {
    @media (min-width: 900px) {
        @content;
    }
}

@mixin gt-md {
    @media (min-width: 1200px) {
        @content;
    }
}

@mixin gt-lg {
    @media (min-width: 1536px) {
        @content;
    }
}
