@import "../../variables.scss";
@import "../../mixins.scss";

.SideMenuHeader {
    background-color: $primary;
    color: white !important;
    padding: 10px;
    height: $toolbar-mobile;

    @include gt-xs {
	padding: 20px;
	height: $toolbar;
    }

    svg {
	color: white;
    }
}

.Sidemenu {
    width: 225px;
    height: 100%;
    background-color: #fafafa;
    padding: 30px 20px;

    .Buttons {

	> a {
	    margin: 0 0 15px 15px;
	    text-decoration: none;
	    color: #212121;
	    font-weight: 500;
	    font-size: 14px;
	    opacity: 0.8;
	    vertical-align: middle;

	    &:hover {
		opacity: 1;
	    }

	    svg {
		margin-right: 10px;
		color: #737373;
	    }
	}

	.Disabled {
	    opacity: 0.5;

	    &:hover {
		opacity: 0.5;
	    }
	}
    }
}
