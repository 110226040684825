.StepControls {
    width: 100%;

    .Wrapper {
	width: 100%;
    }

    .ControlButton {
	color: #000;
	font-weight: bold;
    }
}
