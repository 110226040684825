.UserInfoWrapper {
    margin: 15px;

    p {
	margin-block-start: 0;
	margin-block-end: 0;

	&:first-of-type {
	    margin-bottom: 5px;
	}
    }
}
