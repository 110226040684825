.AuthLayout {
    min-height: 100vh;

    .Brand {
	background-color: #44BDA4;
	position: relative;

	.Footer {
	    width: 100%;
	    position: absolute;
	    bottom: 40px;
	    text-align: center;

	    a {
		margin-left: auto;
		margin-right: auto;
		text-decoration: underline;
		color: #fff;
		font-weight: 600;
		font-size: 18px;
	    }
	}
    }

    .AuthView {

    }

}
