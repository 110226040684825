@import "../../variables.scss";

.MobileRegistration {
    height: 100%;
    background-color: #FFFFFF;
    padding: 25px;
    margin-top: -50px;
}


.RegistrationCard {
    width: 600px;
    height: 400px;

    .Disclaimer {
	color: #6a6a6a;
	font-weight: bold;
    }
}

p {
    color: #6a6a6a;
    font-weight: 600;
}


.Registration {
    p {
	margin-block-start: 10px;
	font-weight: 400;
	font-size: 14px;

	a {
	    color: $primary;
	    opacity: 0.8;
	    font-style: italic;
	    text-decoration: none;


	    &:hover {
		opacity: 1;
	    }
	}
    }
}

.RegistrationFeedback {

    p {
	text-align: center;
	font-size: 18px;
	margin-bottom: 30px;
    }

    a {
	color: $primary;
	text-decoration: none;

	&:hover {
	    text-decoration: underline;
	}

    }

}

.RegistrationFeedbackCard {
    width: 600px;
    height: 400px;
}
