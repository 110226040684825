.LoginCard {
    width: 600px;
    height: 400px;


}


.Registration,
.ForgotPassword {
    p {
	margin-block-start: 10px;
	font-weight: 400;
	font-size: 14px;

	a {
	    color: #44BDA4;
	    font-style: italic;
	    text-decoration: none;

	}
    }
}

.MobileLogin {
    height: 100%;
    background-color: #FFFFFF;
    padding: 25px;
    margin-top: -50p;

    .UsernameWrapper {
	margin-bottom: 20px;
    }

    .PasswordWrapper {
	margin-bottom: 20px;
    }

}
