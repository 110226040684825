.Footer {
    background-color: #000;
    width: 100%;
    height: 8vh;

    .Logo {
	height: 55%;
	margin-right: 40px;
    }
}
