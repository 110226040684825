@import "../../mixins.scss";

.ForgotPasswordWrapper {
    height: 100%;
    padding: 25px;
    background-color: #ffffff;
    margin-top: -50px;

    @include gt-xs {
	height: 100%;
	padding: 50px;
    }

    @include xs {
	div,
	p {
	    margin-bottom: 15px;
	}
    }
}


.ForgotPasswordCard {
    width: 600px;
    height: 400px;

    .Registration,
    .ForgotPassword {
	p {
	    margin-block-start: 10px;
	    font-weight: 400;
	    font-size: 14px;

	    a {
		color: #44BDA4;
		font-style: italic;
		text-decoration: none;

	    }
	}
    }

}
