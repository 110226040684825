@import "../../variables.scss";

.PosterTile {
    background-color: white;
    border: solid 1px #ddd;
    width: 430px;
    border-radius: 0 0 6px 6px;
    margin: 10px;

    .ContentWrapper {
	min-height: 250px;
	padding: 15px;
    }


    .FabricType {
	width: 100%;

	p {
	    margin-block-start: 0;
	    margin-block-end: 0;
	    color: $text;
	    font-size: 15px;
	    font-weight: 400;
	    margin-bottom: 5px;

	    &:first-of-type {
		font-weight: 600;

	    }
	}
    }

    .Story {
	width: 100%;
	color: $text;
	font-size: 14px;
	line-height: 20px;
	font-weight: 400;
	font-style: italic;
	margin-block-start: 10px;
	margin-block-end: 0px;
	hyphens: auto;
	margin-top: 20px !important;
    }

    .Footer {

	p {
	    margin-block-start: 0;
	    margin-block-end: 0;
	}

	.Reference {
	    color: $text;
	    font-size: 13px;
	    font-weight: 900;
	}

	svg {
	    margin-left: 10px;
	}
    }

}

.PosterTilePhoto {
    width: 100%;
    aspect-ratio: 1 / 1;
    background-size: contain;
}
