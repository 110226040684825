@import '../../mixins.scss';
@import "../../variables.scss";

.AppToolbar{
    height: $toolbar-mobile;

    @include gt-xs {
	height: $toolbar;
    }
}

.Logo {
    height: 30px;
    cursor: pointer;

    @include gt-xs {
	height: 55px;
    }
}
