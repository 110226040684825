// colors

$primary: #44BDA4;
$text : #707070;

// dimensions
$toolbar: 100px;
$toolbar-mobile: 60px;

// FOURSOURCE
//
//
//
$foursource-primary: #8B0000;
$form-margin: 35px;
$form-line-margin: 25px;
$form-input-margin: 30px;
