@import "../../variables.scss";

.FoursouceLayout {
    min-height: 100vh;
    background-color: #fff;

    .Brand {
	background-color: $foursource-primary;
	position: relative;

	.Footer {
	    width: 100%;
	    position: absolute;
	    bottom: 40px;
	    text-align: center;

	    a {
		margin-left: auto;
		margin-right: auto;
		text-decoration: underline;
		color: #fff;
		font-weight: 600;
		font-size: 18px;
	    }
	}
    }

    .Content {
	width: 100%;
	height: 92vh;
	background-image: url("../../assets/imgs/foursource/background.jpg");
	background-size: cover;
    }

}
