@import "../../mixins.scss";

.Posters {
    padding: 40px 0px 0px 0px;
    max-width: 100vw;

    @include gt-xs {
	padding: 100px 20px 0px 20px;
    }
}
